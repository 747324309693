import { useEffect } from 'react';
import {
  Box,
  Button,
  Text,
  ToastId,
  useToast,
  UseToastOptions,
} from '@chakra-ui/react';

import { TextLogo } from 'components/Logo';
import { PageDataLoader, usePageData } from 'lib/pageData';
import { FormatCurrency } from 'components/intl';
import { googleTagManagerTrack } from 'lib/trackingHelpers/eventTracking';
import { Link, Router } from 'components/Link';
import { useRouteParams } from 'lib/routeBuilder';

import { ROUTES } from './routes';

type PageData = {
  contact_first_name: string;
  contact_email: string;
  transaction_id: string;
  conversion_amount: number;
  amount_paid: number;
  next_step: 'create_account' | 'continue_to_classroom' | `bundles/${string}`;
};

type PaymentSuccessfulDataLayer = {
  event: 'Payment Successful';
  currency: 'USD';
  value: number;
  transaction_id: string;
  email: string;
};

export const goToAccountCreation = async (
  toast: (options?: UseToastOptions) => ToastId,
): Promise<void> => {
  try {
    const response = await fetch(`/create-account`);

    if (response.redirected) {
      Router.push(response.url);
    }
  } catch {
    toast({
      title: 'Error navigating to account creation',
      status: 'error',
    });
  }
};
export const PaymentSuccessfulPageInner = () => {
  const {
    contact_first_name,
    contact_email,
    transaction_id,
    conversion_amount,
    amount_paid,
    next_step,
  } = usePageData<PageData>();
  const { slug: curriculumSlug } =
    useRouteParams(ROUTES.payment_successful) || {};
  const toast = useToast();

  if (!curriculumSlug) throw new Error('Missing curriculum slug');

  // For google tag manager
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    if (
      window.dataLayer.some((data: unknown) => {
        return (
          (data as PaymentSuccessfulDataLayer).event === 'Payment Successful'
        );
      })
    )
      return;

    googleTagManagerTrack('Payment Successful', {
      currency: 'USD',
      value: conversion_amount,
      email: contact_email,
      transaction_id,
    });
  }, [conversion_amount, transaction_id, contact_email]);

  let CTAButton;

  if (next_step === 'create_account') {
    CTAButton = (
      <Button
        variant="outline"
        colorScheme="brand"
        borderColor="gray.300"
        fontWeight="normal"
        marginY="2"
        onClick={() => goToAccountCreation(toast)}
      >
        Create account
      </Button>
    );
  } else if (next_step === 'continue_to_classroom') {
    CTAButton = (
      <Button href="/" as={Link} variant="outline" colorScheme="brand">
        Continue to Classroom
      </Button>
    );
  } else {
    CTAButton = (
      <Button
        as={Link}
        href={ROUTES.bundle.path(
          {
            slug: curriculumSlug,
            bundle_slug: next_step.split('/')[1],
          },
          {
            bundle_modal: 'true',
          },
        )}
      >
        Continue
      </Button>
    );
  }

  return (
    <Box
      as="main"
      minH="100vh"
      alignItems="stretch"
      display="flex"
      flexDir="column"
    >
      <Box h="16" px="6" py="2" display="flex" alignItems="center">
        <TextLogo />
      </Box>
      <Box
        maxW="2xl"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        marginX="auto"
        paddingY="12"
      >
        <Text as="h3" fontSize="3xl" fontWeight="medium" marginBottom="4">
          Payment Successful ✅
        </Text>

        <Box marginBottom="2" textAlign="center">
          <Box padding="8">
            <Text as="h4" fontSize="xl">
              Welcome to Stepful {contact_first_name}! 🎉
            </Text>
            <Text
              as="h5"
              marginTop="4"
              marginBottom="8"
              paddingX="2"
              fontSize="md"
              fontWeight="medium"
            >
              Amount paid: <FormatCurrency value={amount_paid} />
            </Text>

            <Text
              marginBottom="2"
              paddingX="2"
              maxW="lg"
              textAlign={next_step === 'create_account' ? 'left' : 'center'}
              fontSize="md"
              color="gray.900"
            >
              We&apos;re incredibly excited to help you land your dream job in
              healthcare.
              {next_step === 'create_account' &&
                ' Create your Stepful account now to access the online classroom and start learning.'}
            </Text>
          </Box>
        </Box>
        {CTAButton}
      </Box>
    </Box>
  );
};

export const PaymentSuccessfulPage: React.FC = () => {
  return (
    <PageDataLoader>
      <PaymentSuccessfulPageInner />
    </PageDataLoader>
  );
};
