import { Box, Checkbox, Progress, TableRowProps, Text } from '@chakra-ui/react';
import * as _ from 'lodash-es';
import { memo } from 'react';

import type {
  ClassTimingOption,
  CohortTiming,
} from 'bundles/Classroom/Onboarding/data';
import { ShadowContentBox } from 'bundles/Classroom/shared';
import { DEFAULT_FALLBACK_SERVER_TIMEZONE, FormatTime } from 'components/intl';
import { useTimezone } from 'components/SessionContext';
import { ROW_STYLES_PROP, ViewTable } from 'components/tables/ViewTable';
import { timezoneLabel } from 'lib/utils/time';

const dateFormat = (d: DateString) => (
  <strong>
    <FormatTime time={d} format="MMM d," />
    <br />
    <FormatTime time={d} format="yyyy" />
  </strong>
);

const PreviewClassTimings_: React.FC<{
  classTimingOptions: ClassTimingOption[];
  selectedCohortTiming: number | null;
  setSelectedCohortTiming: (id: number | null) => void;
}> = ({
  classTimingOptions: options,
  selectedCohortTiming,
  setSelectedCohortTiming,
}) => {
  const rows = _.sortBy(
    Object.values(_.groupBy(options, (o) => o.cohort.id)).map((cts) => ({
      cohort: cts[0].cohort,
      timings: cts,
    })),
    (c) => c.cohort.start_date,
  ).flatMap(({ cohort, timings }, i) => {
    return timings.map(({ cohort_timing }, j) => ({
      cohort: j === 0 ? cohort : null,
      cohort_timing: {
        ...cohort_timing,
        selected: cohort_timing.id === selectedCohortTiming,
      },
      // show border around grouped rows
      [ROW_STYLES_PROP]: {
        borderTop: j === 0 && i !== 0 ? '1px solid' : 'none',
        borderColor: 'gray.100',
        opacity:
          (selectedCohortTiming && cohort_timing.id !== selectedCohortTiming) ||
          cohort_timing.status === 100
            ? 0.4
            : 1,
      } satisfies TableRowProps,
    }));
  });

  const timezone = useTimezone() || DEFAULT_FALLBACK_SERVER_TIMEZONE;
  const tzLabel = timezoneLabel(timezone, 'short') || timezone;

  return (
    <ShadowContentBox>
      <ViewTable
        nullCell={null}
        data={rows}
        onRowClick={(r) => {
          if (r.cohort_timing.status === 100) return;

          if (r.cohort_timing.id === selectedCohortTiming) {
            setSelectedCohortTiming(null);
          } else {
            setSelectedCohortTiming(r.cohort_timing.id);
          }
        }}
        tableProps={{
          size: 'sm',
          variant: 'unstyled',
          sx: {
            th: {
              bg: 'gray.50',
              color: 'gray.500',
              fontWeight: 'normal',
              px: 0,
              textAlign: 'center',
            },
            // hide `end_date` column on mobile
            '@media (max-width: 48em)': {
              'th:nth-child(3), td:nth-child(3)': {
                display: 'none',
              },
            },
          },
        }}
        cellProps={{
          padding: '0.85rem 0', // override default padding
          fontSize: 'xs',
          color: 'gray.600',
          textAlign: 'center',
        }}
        columns={[
          {
            field: 'cohort_timing' as const,
            label: '',
            format: (ct: CohortTiming & { selected: boolean }) => {
              return (
                <Checkbox
                  colorScheme="pink"
                  isChecked={ct.selected}
                  isDisabled={ct.status === 100}
                  onChange={(s) => {
                    setSelectedCohortTiming(s.target.checked ? null : ct.id);
                  }}
                />
              );
            },
          },
          {
            field: 'cohort_timing.kickoff_date',
            label: 'Start',
            format: dateFormat,
          },
          { field: 'cohort.end_date', label: 'Graduation', format: dateFormat },
          {
            field: 'cohort_timing',
            label: 'Timing',
            format: (ct: CohortTiming & { selected: boolean }) => (
              <Box>
                {ct.phase_label ? (
                  <>
                    <strong>{ct.phase_label}:</strong>{' '}
                  </>
                ) : null}
                {ct.days},<br />
                <FormatTime time={ct.start_time} format="h:mmaaa" /> to{' '}
                <FormatTime time={ct.end_time} format="h:mmaaa" /> {tzLabel}
              </Box>
            ),
          },
          {
            field: 'cohort_timing.status',
            label: 'Status',
            width: 16,
            format: (status: number | null) =>
              status != null ? (
                <Box>
                  <Progress
                    value={status}
                    rounded="full"
                    h="6px"
                    border="1px"
                    borderColor="gray.600"
                    sx={{ div: { bg: 'gray.600' } }} // bar color
                    bg="white" // background color
                  />
                  <Text textAlign="center" color="gray.500">
                    {status}% Full
                  </Text>
                </Box>
              ) : null,
          },
        ]}
      />
    </ShadowContentBox>
  );
};

export const PreviewClassTimings = memo(PreviewClassTimings_);
