/* eslint-disable react/no-children-prop */
import { Route, Switch } from 'react-router-dom';

import type { ClassroomProps } from 'bundles/Classroom/types';
import { PageDataRoot } from 'lib/pageData';
import { EditPasswordPage } from 'bundles/Auth/edit-password';
import { NewPasswordPage } from 'bundles/Auth/new-password';
import { LoginPage } from 'bundles/Auth/login';

import { SignupFlow } from './SignupFlow';
import { ROUTES } from './routes';
import { PaymentSuccessfulPage } from './PaymentSuccessful';
import { BundlePage, BundleSuccessPage } from './Bundle';

export const Signup: React.FC<ClassroomProps> = (props) => {
  return (
    <PageDataRoot rootProps={props}>
      <Switch>
        <Route exact path={ROUTES.sign_in.pattern} children={<LoginPage />} />
        <Route
          exact
          path={ROUTES.new_password.pattern}
          children={<NewPasswordPage />}
        />
        <Route
          exact
          path={ROUTES.edit_password.pattern}
          children={<EditPasswordPage />}
        />
        <Route
          exact
          path={ROUTES.signup_flow.pattern}
          children={<SignupFlow />}
        />
        <Route
          exact
          path={ROUTES.payment_successful.pattern}
          children={<PaymentSuccessfulPage />}
        />
        <Route exact path={ROUTES.bundle.pattern} children={<BundlePage />} />
        <Route
          exact
          path={ROUTES.bundle_success.pattern}
          children={<BundleSuccessPage />}
        />
      </Switch>
    </PageDataRoot>
  );
};
