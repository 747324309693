import { useEffect, useState } from 'react';

export const useLocalStorageOnce = <V>(key: string, getValue: () => V) => {
  const [value, setValue] = useState<V | null>(null);
  useEffect(() => {
    const val = localStorage.getItem(key);
    if (val != null) {
      try {
        setValue(JSON.parse(val));
      } catch {}
    } else {
      setValue(getValue());
      localStorage.setItem(key, JSON.stringify(getValue()));
    }
  }, [key, getValue]);
  return value;
};
