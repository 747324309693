import {
  Button,
  chakra,
  Link as CLink,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { ShadowContentBox } from 'bundles/Classroom/shared';
import { ROUTES } from 'bundles/Signup/routes';
import { Link } from 'components/Link';
import { request, RequestError } from 'lib/request';

import { AuthLayout } from './layout';

const editPassword = async (
  password: string,
  password_confirmation: string,
) => {
  const reset_password_token = new URLSearchParams(window.location.search).get(
    'reset_password_token',
  );
  if (!reset_password_token) throw new Error('Missing reset_password_token');

  await request({
    url: '/users/password',
    method: 'PUT',
    body: {
      user: {
        password,
        password_confirmation,
        reset_password_token,
      },
    },
  });
};

type Props = {
  new_user?: {
    email: string;
    first_name: string;
  };
  is_first_access: boolean;
};

const PasswordForm: React.FC<Props> = ({ new_user, is_first_access }) => {
  const form = useForm({
    defaultValues: {
      password: '',
      password_confirmation: '',
    },
  });

  const [loading, setLoading] = useState(false);

  const toast = useToast();

  const {
    password: passwordError,
    password_confirmation: passwordConfirmationError,
  } = form.formState.errors;

  return (
    <chakra.form
      onSubmit={form.handleSubmit(async (values) => {
        setLoading(true);

        try {
          await editPassword(values.password, values.password_confirmation);

          toast({
            title: 'Password updated successfully',
            status: 'success',
          });

          await new Promise((r) => setTimeout(r, 500));
          window.location.href = '/';
        } catch (err) {
          toast({
            title: (err as RequestError).message,
            status: 'error',
          });
        }
        setLoading(false);
      })}
      mx="auto"
      maxW="container.sm"
      mb="10"
      mt="24"
    >
      <Text mb="8" fontWeight="bold" fontSize="3xl" textAlign="center">
        {is_first_access ? 'Create account' : 'Reset password'}
      </Text>
      {is_first_access ? (
        <Text textAlign="center" mb="10" color="gray.500">
          Welcome to Stepful
          {new_user?.first_name ? <>, {new_user?.first_name}</> : null}!
          Let&apos;s get you all set for your upcoming class.
        </Text>
      ) : null}

      <ShadowContentBox>
        {is_first_access ? (
          <FormControl mb="8" isReadOnly>
            <FormLabel color="gray.600" mb="0.5">
              Username:
            </FormLabel>
            <Input
              type="email"
              value={new_user?.email}
              bg="gray.100"
              color="gray.700"
            />
          </FormControl>
        ) : null}
        <FormControl mb="4" isInvalid={!!passwordError}>
          <Input
            {...form.register('password', {
              required: 'Required',
              minLength: {
                value: 8,
                message: 'Must be at least 8 characters',
              },
              maxLength: {
                value: 128,
                message: 'Must be at most 128 characters',
              },
            })}
            type="password"
            placeholder="New password"
            autoComplete="new-password"
          />

          <FormErrorMessage>{passwordError?.message}</FormErrorMessage>
        </FormControl>
        <FormControl mb="4" isInvalid={!!passwordConfirmationError}>
          <Input
            {...form.register('password_confirmation', {
              validate: (v) =>
                v === form.getValues().password || 'Passwords must match',
            })}
            type="password"
            placeholder="Confirm new password"
            autoComplete="new-password"
          />

          <FormErrorMessage>
            {passwordConfirmationError?.message}
          </FormErrorMessage>
        </FormControl>

        <Button
          w="full"
          colorScheme="brand"
          type="submit"
          isLoading={loading}
          isDisabled={loading}
        >
          Create Account
        </Button>

        {is_first_access ? (
          <Text
            mt="2"
            textAlign="center"
            color="gray.600"
            fontSize="sm"
            fontStyle="italic"
          >
            You agree to receive email and SMS communications, which you may opt
            out of at any time.
          </Text>
        ) : null}
      </ShadowContentBox>

      {!is_first_access ? (
        <Text textAlign="center" color="gray.600" mt="6">
          Already have an account?{' '}
          <CLink as={Link} href={ROUTES.sign_in.path()} color="brand.500">
            Sign in here
          </CLink>
        </Text>
      ) : null}
    </chakra.form>
  );
};

export const EditPasswordPage: React.FC = () => {
  return <AuthLayout child={PasswordForm} />;
};
