import { Box, Icon, Text } from '@chakra-ui/react';
import { addMinutes } from 'date-fns';

import { AlertIcon, ClockIcon } from 'bundles/Classroom/icons';
import { useCountdown } from 'lib/utils/useCountdown';
import { useLocalStorageOnce } from 'lib/utils/useLocalStorageOnce';

const useSignupCountdown = () => {
  const targetTime = useLocalStorageOnce(`signup-countdown-target`, () =>
    addMinutes(new Date(), 15).getTime(),
  );
  const remainingSeconds = useCountdown(targetTime);
  if (remainingSeconds == null) return null;
  return remainingSeconds;
};

export const SelectPaymentPlanCountdownBanner: React.FC = () => {
  const remainingTime = useSignupCountdown();
  if (remainingTime == null) return null;

  return (
    <Box
      backgroundColor="red.100"
      w="full"
      pt="4"
      pb="4"
      position="sticky"
      top="0"
      zIndex={10}
    >
      <Text
        color="brand.500"
        fontSize="sm"
        w="fit-content"
        mx="auto"
        display="flex"
        alignItems="center"
        as="span"
      >
        {remainingTime > 0 ? (
          <>
            <Icon as={ClockIcon} boxSize="6" mr="1.5" />
            Saving your seat for the next
            <Text
              color="brand.500"
              fontWeight="700"
              fontSize="sm"
              w="fit-content"
              as="span"
              ml="1"
              width="70px"
            >
              {Math.floor(remainingTime / 60).toString()}:
              {(remainingTime % 60).toString().padStart(2, '0')} min
            </Text>
          </>
        ) : (
          <>
            <Icon as={AlertIcon} boxSize="6" mr="1.5" />
            Your class is
            <Text fontWeight="700" mx="1">
              almost full,
            </Text>
            don&apos;t lose your spot!
          </>
        )}
      </Text>
    </Box>
  );
};
