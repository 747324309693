import {
  useToast,
  chakra,
  Text,
  Input,
  Button,
  Link as CLink,
  FormControl,
  Icon,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { request, RequestError } from 'lib/request';
import { ShadowContentBox } from 'bundles/Classroom/shared';
import { ExternalLinkIcon } from 'bundles/Classroom/icons';
import { ROUTES } from 'bundles/Signup/routes';
import { Link } from 'components/Link';

import { AuthLayout } from './layout';

const LANDING_SITE_URL = 'https://www.stepful.com';

const login = async (
  email: string,
  password: string,
  sendLoginLink: boolean,
) => {
  const { url } = await request<{ url: string }>({
    url: '/users/sign_in.json',
    method: 'POST',
    body: {
      user: {
        email,
        ...(sendLoginLink ? { send_login_link: true } : { password }),
      },
    },
  });

  window.location.href = url;
};

type Props = {
  login_link_form?: boolean;
};

const LoginForm: React.FC<Props> = ({ login_link_form }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  return (
    <chakra.form
      onSubmit={form.handleSubmit(async (values) => {
        setLoading(true);

        try {
          await login(values.email, values.password, !!login_link_form);
        } catch (err) {
          toast({
            title: (err as RequestError).message,
            status: 'error',
          });
          setLoading(false);
        }
      })}
      mx="auto"
      maxW="container.sm"
      mb="10"
      mt="24"
    >
      <Text mb="8" fontWeight="bold" fontSize="3xl" textAlign="center">
        Sign In to your Account
      </Text>
      <Text textAlign="center" mb="10" color="gray.500">
        {!login_link_form ? (
          <>
            Sign in using your credentials or
            <br />
            <CLink
              as={Link}
              href={ROUTES.sign_in.path(undefined, { login_link_form: 1 })}
              color="brand.500"
            >
              get a login link via email
            </CLink>
          </>
        ) : (
          <>
            <CLink as={Link} href={ROUTES.sign_in.path()} color="brand.500">
              Sign in using your credentials
            </CLink>{' '}
            or
            <br />
            get a login link via email
          </>
        )}
      </Text>

      <ShadowContentBox>
        <FormControl mb="4">
          <Input
            {...form.register('email', { required: true })}
            type="email"
            placeholder="Email address"
            autoComplete="email"
          />
        </FormControl>
        {!login_link_form ? (
          <>
            <FormControl mb="4">
              <Input
                {...form.register('password', { required: true })}
                type="password"
                placeholder="Password"
                autoComplete="current-password"
              />
            </FormControl>
            <Text textAlign="right" mb="6">
              <CLink
                as={Link}
                href={ROUTES.new_password.path()}
                color="brand.500"
              >
                Forgot your password?
              </CLink>
            </Text>
          </>
        ) : null}

        <Button
          w="full"
          colorScheme="brand"
          type="submit"
          isLoading={loading}
          isDisabled={loading}
        >
          {!login_link_form ? 'Sign in' : 'Send me a login link'}
        </Button>
      </ShadowContentBox>

      <Text textAlign="center" mt="16" mb="2" color="gray.600">
        Don&apos;t have an account?
        <CLink href={LANDING_SITE_URL} isExternal color="brand.500" ml="2">
          Sign up here{' '}
          <Icon
            as={ExternalLinkIcon}
            boxSize="4"
            display="inline"
            verticalAlign="baseline"
          />
        </CLink>
      </Text>
      <Text
        textAlign="center"
        color="gray.500"
        fontSize="sm"
        fontStyle="italic"
      >
        Sign up will redirect you to our website
      </Text>
    </chakra.form>
  );
};

export const LoginPage: React.FC = () => {
  return <AuthLayout child={LoginForm} />;
};
