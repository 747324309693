import { MainNav } from 'components/navbar/MainNav';
import { PageDataLoader, usePageData } from 'lib/pageData';

const ChildWithData = <Props extends Record<string, unknown>>({
  child: Child,
}: {
  child: React.FC<Props>;
}) => {
  const props = usePageData<Props>();
  return <Child {...props} />;
};

export const AuthLayout = <Props extends Record<string, unknown>>({
  child,
}: {
  child: React.FC<Props>;
}) => {
  return (
    <>
      <MainNav showLogin={false} />
      <main>
        <PageDataLoader>
          <ChildWithData child={child} />
        </PageDataLoader>
      </main>
    </>
  );
};
