import { useCallback, useEffect, useState } from 'react';

export const useCountdown = (targetTime: number | null, interval = 1000) => {
  const calculateRemainingSeconds = useCallback(() => {
    if (!targetTime) return null;
    const now = new Date().getTime();
    return Math.max(Math.floor((targetTime - now) / 1000), 0);
  }, [targetTime]);

  const [remainingSeconds, setRemainingSeconds] = useState<number | null>(
    calculateRemainingSeconds,
  );

  useEffect(() => {
    if (!targetTime) return;
    const timerId = setInterval(() => {
      const s = calculateRemainingSeconds();
      setRemainingSeconds(s);
      if (s !== null && s <= 0) clearInterval(timerId);
    }, interval);
    return () => clearInterval(timerId);
  }, [targetTime, interval, calculateRemainingSeconds]);

  return remainingSeconds;
};
