import React, { useState } from 'react';
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import {
  Box,
  Button,
  Center,
  Divider,
  Img,
  Link,
  Spinner,
  Text,
} from '@chakra-ui/react';

import { request } from 'lib/request';
import { useRootData } from 'lib/pageData';
import { ClassroomProps } from 'bundles/Classroom/types';
import { PaymentPlan, useSignupFlow } from 'bundles/Signup/signupFlowData';
import { FormatCurrency } from 'components/intl';
import { trackError } from 'lib/errorTracking';

import stripeBadge from '../../../assets/images/stripe-badge-transparent.png';

export type PaymentElementFormProps = {
  loading?: boolean;
  inputId?: string;
  paymentPlan: PaymentPlan;
  contactDetails?: string;
};

const PaymentElementForm: React.FC<PaymentElementFormProps> = ({
  loading: outerLoading,
  inputId,
  paymentPlan,
}) => {
  const { curriculumSlug, contact_data } = useSignupFlow();
  const stripe = useStripe();
  const elements = useElements();
  elements?.update({
    mode: 'payment',
    amount: paymentPlan.one_off_amount,
    currency: 'usd',
    setup_future_usage: 'off_session',
  });

  const [innerLoading, setInnerLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const loading = outerLoading || innerLoading;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Stripe.js has not yet loaded.
    if (!stripe || !elements) return;
    if (loading) return;

    setInnerLoading(true);

    try {
      const paymentElement = elements.getElement('payment');
      if (!paymentElement) return;

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw new Error(submitError.message);
      }

      // Create the PaymentIntent and obtain clientSecret when we are ready to confirm
      // See https://docs.stripe.com/payments/accept-a-payment-deferred
      const payment_intent_secret = await request<{
        client_secret: string;
      }>({
        url: `/curricula/${curriculumSlug}/signup/create-payment-intent/${paymentPlan.id}`,
        method: 'POST',
      });

      const { client_secret: clientSecret } = payment_intent_secret;

      // Now confirm the payment
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${window.location.origin}/curricula/${curriculumSlug}/signup/payment-successful`,
        },
      });
      if (error) {
        setErrorMessage(error.message);
      }
    } catch (err) {
      setErrorMessage(
        'An error occurred while processing your payment. Please try again.',
      );
      void trackError(err as Error);
    } finally {
      setInnerLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box mt="8" mb={2}>
        <PaymentElement
          id={inputId}
          options={{
            terms: { card: 'never' },
            defaultValues: {
              billingDetails: {
                name:
                  [contact_data?.first_name, contact_data?.last_name]
                    .filter(Boolean)
                    .join(' ') || undefined,
                email: contact_data?.email,
                phone: contact_data?.phone_number,
              },
            },
          }}
        />
      </Box>
      <Button
        type="submit"
        isDisabled={!stripe || loading}
        isLoading={loading}
        colorScheme="brand"
        w="full"
      >
        {loading && <Spinner />}
        Pay <FormatCurrency value={paymentPlan.one_off_amount} />
      </Button>
      {errorMessage && (
        <Text as="p" color="red">
          {errorMessage}
        </Text>
      )}
      <Img
        src={stripeBadge}
        alt="Guaranteed safe and secure checkout powered by Stripe"
      />
      <Divider mb="2" />
      <Text as="p" align="center" fontSize="xs">
        By confirming your payment, you allow Stepful, Inc. to charge your card
        for this payment and future payments, and you agree to our{' '}
        <Link href="https://stepful.com/legal">terms of service</Link>.
      </Text>
    </form>
  );
};

let stripePromise: Promise<Stripe | null> | null = null;

export const StripeCardPaymentForm: React.FC<PaymentElementFormProps> = (
  props,
) => {
  const stripePublicKey =
    useRootData<ClassroomProps>().config?.stripe_public_key;

  // make sure to avoid recreating the `Stripe` object on every render.
  if (!stripePromise && stripePublicKey) {
    try {
      stripePromise = loadStripe(stripePublicKey);
    } catch {}
  }
  if (!stripePromise) {
    return (
      <Center>
        <Spinner size="xl" />
      </Center>
    );
  }
  return (
    <Elements stripe={stripePromise}>
      <PaymentElementForm {...props} />
    </Elements>
  );
};
